import React, { useRef, useState } from 'react';
import { MdOutlineEmail } from 'react-icons/md';
import { BsMessenger } from 'react-icons/bs';
import { AiFillPhone } from 'react-icons/ai';
import './contact.css';
import emailjs from 'emailjs-com';
/* global gtag */

const Contact_PL = () => {
  const form = useRef();
  const [isConsentChecked, setIsConsentChecked] = useState(false); // Nowy stan dla checkboxa

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isConsentChecked) {
      alert('Musisz zaznaczyć pole wyboru o przetwarzaniu danych, aby kontynuować.');
      return;
    }

    emailjs.sendForm('small.pl', 'template_xkik823', form.current, 'T4kwLmG3mnW7Kit7u')
      .then((result) => {
        console.log(result.text);

        gtag('event', 'ads_conversion_Formularz_kontaktowy_1', {
          'event_callback': function () {
            console.log('Konwersja zmierzona!');
          },
          'event_timeout': 2000
        });
        gtag('event', 'conversion_event_submit_lead_form_1', {
          'event_callback': function () {
            console.log('Konwersja zmierzona!');
          },
          'event_timeout': 2000
        });
      })
      .catch((error) => {
        console.log(error.text);
      });

    e.target.reset();
  };


  return (
    <section id='contact'>
      <p data-aos="fade-right">
        Masz pytania? Chcesz zacząć projekt?
        Oferujemy indywidualne podejście i kompleksowe wsparcie – napisz do nas!
      </p>
      <h2 data-aos="fade-left">Skontaktuj się z nami!</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>kontakt@woosu.pl</h5>
            <a href="mailto:kontakt@woosu.pl" target="_blank" rel="noopener noreferrer">
              Wyślij wiadomość
            </a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Twoje imię i nazwisko' required />
          <input type="email" name='email' placeholder='Twój adres email' required />
          <textarea name="message" cols="30" rows="7" placeholder='Opisz swój projekt lub zapytanie' required></textarea>

          <div className="checkbox-container">
            <input
              type="checkbox"
              id="consent"
              checked={isConsentChecked}
              onChange={() => setIsConsentChecked(!isConsentChecked)}
            />
            <label htmlFor="consent">
              Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z polityką prywatności.
            </label>
          </div>

          <button type='submit' className='btn btn-primary'>
            Wyślij zapytanie
          </button>
        </form>
      </div>
    </section>
  )
}

export default Contact_PL;
